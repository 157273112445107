import { Divider, List, ListSubheader } from '@mui/material';

import { SidebarMenuAction } from '@/components/common/Sidebar/SidebarMenuAction';
import { DrawerMenuAction } from '@/components/common/Sidebar/types';

type SidebarMenuSectionProps = {
  title?: string | null;
  actions: DrawerMenuAction[];
  showDivider: boolean;
};

export const SidebarMenuSection = ({ title, actions, showDivider = false }: SidebarMenuSectionProps) => (
  <>
    <List subheader={title && <ListSubheader sx={{ bgcolor: 'unset' }}>{title}</ListSubheader>}>
      {actions.map(
        (action: DrawerMenuAction) => !action.isHidden && <SidebarMenuAction key={`${action.label}-key`} {...action} />,
      )}
    </List>
    {showDivider && <Divider />}
  </>
);
