import { AxiosResponse } from 'axios';

import { request } from '@/api/axios';
import { InternalEndpoints, WaasEndpoints } from '@/api/endpoints';

import { UserTypePayload, WaasParPayload, WaasRegisterUserDetails, WaasVerifyCodePayload } from '@/types/interfaces';
import {
  InternalIsWaasResponse,
  UserTypeResponse,
  WaasCodeChallengeResponse,
  WaasIdentResponse,
  WaasParResponse,
} from '@/types/responses';
import { RequestWithProcessId } from '@/types/types';

import { isBrowser } from '@/utils/environment';
import { replaceQueryParams } from '@/utils/generatePath';

export const waasApi = {
  par: async (body: WaasParPayload): Promise<WaasParResponse> => {
    const formData = new URLSearchParams({
      clientId: body.clientId,
      loginHint: body.loginHint,
      processId: body.processId,
      codeChallenge: body.codeChallenge,
    });

    const { data } = await request.post(InternalEndpoints.WaasAuth, formData);
    return data;
  },
  registerUserDetails: async (body: WaasRegisterUserDetails): Promise<void> => {
    const { data } = await request.put(InternalEndpoints.RegisterUserDetails, body);
    return data;
  },
  userType: async (payload: UserTypePayload): Promise<UserTypeResponse> => {
    const headers = {
      email: payload.email,
    };
    const { data } = await request.get(WaasEndpoints.UserType, { headers });
    return data;
  },
  codeChallenge: async (params: RequestWithProcessId): Promise<WaasCodeChallengeResponse> => {
    const { data } = await request.get(replaceQueryParams(InternalEndpoints.CodeChallenge, params));
    return data;
  },
  verifyCode: async (params: RequestWithProcessId, body: WaasVerifyCodePayload): Promise<number> => {
    const { status } = await request.post(
      `${process.env.VERIMI_API_WAAS_URL}${replaceQueryParams(WaasEndpoints.VerifyCode, params)}`,
      body,
    );
    return status;
  },
  isWaas: async (): Promise<InternalIsWaasResponse> => {
    const { data } = await request.get(InternalEndpoints.IsWaas);
    return data;
  },
  ident: async (processId: string): Promise<WaasIdentResponse> => {
    const url = isBrowser() ? WaasEndpoints.Ident : `${process.env.VERIMI_API_WAAS_URL}${WaasEndpoints.Ident}`;
    const { data } = await request.get<unknown, AxiosResponse<Partial<WaasIdentResponse>>>(
      replaceQueryParams(url, { processId }),
    );
    return {
      egkVerification: data.egkVerification ?? '',
      suspectedFraudIndicator: data.suspectedFraudIndicator ?? false,
      ocrDataEditingAllowed: !!data.ocrDataEditingAllowed,
    };
  },
};
