export const Verimi = () => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.59521 19.4219L9.34356 26.2927L12.0919 19.4219H6.59521Z"
        fill="#0D9570"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-0.000488281 19.4219L4.39681 30.4152H7.69484L3.29754 19.4219H-0.000488281Z"
        fill="#0D9570"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1474 28.4065L15.4272 23.8193H13.5703L16.2192 30.4134H18.0894L20.7378 23.8193H18.8812L17.1474 28.4065Z"
        fill="#0D9570"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5808 26.4822C22.6494 25.8267 23.1133 25.0759 24.1919 25.0759C25.3388 25.0759 25.7753 25.8539 25.8302 26.4822H22.5808ZM24.1919 23.6562C22.1987 23.6562 20.7788 25.1987 20.7788 27.1103C20.7788 29.2262 22.2941 30.5779 24.3011 30.5779C25.325 30.5779 26.3624 30.2634 27.0181 29.6628L26.2398 28.516C25.8166 28.9257 25.0519 29.1581 24.4923 29.1581C23.3727 29.1581 22.7175 28.4888 22.5944 27.6837H27.4957V27.2738C27.4957 25.1306 26.1716 23.6562 24.1919 23.6562Z"
        fill="#0D9570"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.0932 24.7073V23.8199H28.3594V30.414H30.0932V26.059C30.3797 25.6359 31.1442 25.308 31.7175 25.308C31.9225 25.308 32.0724 25.3219 32.1956 25.3492V23.6562C31.3763 23.6562 30.5708 24.1338 30.0932 24.7073Z"
        fill="#0D9570"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.2315 21.0469C33.6717 21.0469 33.2075 21.4972 33.2075 22.0706C33.2075 22.6441 33.6717 23.1084 34.2315 23.1084C34.805 23.1084 35.2689 22.6441 35.2689 22.0706C35.2689 21.4972 34.805 21.0469 34.2315 21.0469Z"
        fill="#0D9570"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.3721 30.4134H35.1058V23.8193H33.3721V30.4134Z"
        fill="#0D9570"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.6123 23.6562C43.6022 23.6562 42.7284 24.2569 42.3459 24.844C42.1141 24.1066 41.4858 23.6562 40.5167 23.6562C39.5065 23.6562 38.6466 24.2842 38.3598 24.6664V23.8199H36.626V30.414H38.3598V25.9635C38.6193 25.6082 39.124 25.1989 39.7247 25.1989C40.4349 25.1989 40.7079 25.6359 40.7079 26.2503V30.414H42.4554V25.9635C42.7011 25.6082 43.206 25.1989 43.8203 25.1989C44.5305 25.1989 44.8035 25.6359 44.8035 26.2503V30.414H46.551V25.6496C46.551 24.2978 45.8273 23.6562 44.6123 23.6562Z"
        fill="#0D9570"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.9634 23.1083C49.537 23.1083 50.0008 22.6441 50.0008 22.0706C50.0008 21.4972 49.537 21.0469 48.9634 21.0469C48.4036 21.0469 47.9395 21.4972 47.9395 22.0706C47.9395 22.6441 48.4036 23.1083 48.9634 23.1083Z"
        fill="#0D9570"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.0962 30.4134H49.8299V23.8193H48.0962V30.4134Z"
        fill="#0D9570"
      />
    </svg>
  );
};
