import { useCallback, useMemo } from 'react';

import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import { CancelOutlined, DownloadOutlined, Language } from '@mui/icons-material';

import { ToggleLocale } from '@/components/common/Sidebar/ToggleLocale';
import { DrawerMenuGroup } from '@/components/common/Sidebar/types';
import { WaasCancelModalContent } from '@/components/waas/CancelModalContent';

import { IdentStatus, Locales, VerificationStatus } from '@/constants/common';

import { useModal } from '@/hooks/useModal';

import { commonQueries } from '@/queries/common';
import { identQueries } from '@/queries/ident';
import { verifyQueries } from '@/queries/verify';
import { waasQueries } from '@/queries/waas';

import { RouteName, VERIFY_ROUTES, WaasRouteName } from '@/routes/routes';

import { QueryParamsWithIdent, RequestWithProcessId } from '@/types/types';

import { isWebview } from '@/utils/environment';
import { iOsProcessCancelHandler } from '@/utils/iosHandlers';

export const useMenuStructure = ({ handleDrawerClose }: { handleDrawerClose: () => void }) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { confirmModal } = useModal();

  const useIsWaas = waasQueries.useIsWaas();
  const isWaasApp = useIsWaas.data?.isWaas;

  const { processId } = router.query as Partial<RequestWithProcessId<QueryParamsWithIdent>>;

  const useStatusesMutation = commonQueries.useStatusesMutation();
  const useIdentCancelProcess = identQueries.useCancelProcess();
  const useIdentRetrieveRedirectUrl = identQueries.useRetrieveRedirectUrl();
  const useVerifyCancelProcess = verifyQueries.useCancelProcess();
  const useVerifyRetrieveRedirectUrl = verifyQueries.useRetrieveRedirectUrl();

  const useServiceProviderId = commonQueries.useServiceProviderId({ processId: processId as string });
  const useServiceProviderData = commonQueries.useServiceProviderData({
    serviceProviderId: useServiceProviderId?.data?.serviceProviderId || '',
  });

  const { locale: currentLocale = Locales.de, pathname } = router;

  const switchLocale = useCallback(
    (locale: string) => {
      const path = router.asPath;

      return router.replace(path, path, { locale });
    },
    [router],
  );

  const handleLanguageChange = useCallback(
    (newValue: Locales) => {
      switchLocale(newValue);
    },
    [switchLocale],
  );

  const cancelProcess = useCallback(async () => {
    if (!processId) {
      console.error('processId is not defined');
      return;
    }

    const statuses = await useStatusesMutation.mutateAsync({ processId });

    const { idents: [ident] = [], verifications: [verification] = [] } = statuses ?? {};
    const identSubProcessId: string | undefined = ident?.id;
    const verifySubProcessId: string | undefined = verification?.id;

    const subProcessId = VERIFY_ROUTES.includes(pathname as RouteName) ? verifySubProcessId : identSubProcessId;

    if (!subProcessId) {
      console.error('subProcessId is not defined');
      return;
    }

    const cancelMethod = VERIFY_ROUTES.includes(pathname as RouteName) ? useVerifyCancelProcess : useIdentCancelProcess;

    if (
      subProcessId &&
      (VERIFY_ROUTES.includes(pathname as RouteName)
        ? verification.status !== VerificationStatus.Canceled
        : ident.status !== IdentStatus.Canceled)
    ) {
      await cancelMethod.mutateAsync({ processId, subProcessId });
    }

    if (isWebview()) {
      iOsProcessCancelHandler();
    } else {
      const retrieveUrlMethod = VERIFY_ROUTES.includes(pathname as RouteName)
        ? useVerifyRetrieveRedirectUrl
        : useIdentRetrieveRedirectUrl;

      try {
        const { redirectUrl } = await retrieveUrlMethod.mutateAsync({ processId, subProcessId });
        await router.push({
          pathname: redirectUrl,
          query: { processId, status: IdentStatus.Canceled },
        });
      } catch (e) {
        await router.push('/');
      }
    }
  }, [
    processId,
    router,
    pathname,
    useIdentCancelProcess,
    useIdentRetrieveRedirectUrl,
    useStatusesMutation,
    useVerifyCancelProcess,
    useVerifyRetrieveRedirectUrl,
  ]);

  const handleProcessCancel = useCallback(async () => {
    try {
      if (isWaasApp) {
        await confirmModal({
          title: t('cancelProcess.title'),
          confirmationButtonLabel: t<string>('cancelProcess.confirmButton'),
          buttonCloseLabel: t<string>('button.no'),
          maxWidth: 'xs',
          content: <WaasCancelModalContent />,
        });
      } else {
        await confirmModal({
          title: t('modals.discardIdentification.title'),
          confirmationButtonLabel: t<string>('modals.discardIdentification.confirmationButtonLabel'),
          buttonCloseLabel: t<string>('button.back'),
          content: t<string>('modals.discardIdentification.content', {
            serviceProviderName: useServiceProviderData?.data?.name,
          }),
          maxWidth: 'xs',
        });
      }

      await cancelProcess();
    } catch (error) {
      console.error('Error while cancelling process', error);
    }

    handleDrawerClose();
  }, [t, cancelProcess, handleDrawerClose, isWaasApp, confirmModal, useServiceProviderData?.data?.name]);

  const handleWaasDownloadApp = useCallback(async () => {
    await router.push({
      pathname: WaasRouteName.WaasDownloadApp,
    });
  }, [router]);

  return useMemo<DrawerMenuGroup[]>(() => {
    const faqLink = `https://verimi.de/${currentLocale}/faq`;
    const imprintLink = `https://verimi.de/${currentLocale}/imprint`;

    return [
      {
        title: t('sideMenu.processLabel'),
        actions: [
          {
            label: isWaasApp ? t('sideMenu.actions.cancel') : t('sideMenu.actions.discard'),
            Icon: CancelOutlined,
            handleClick: handleProcessCancel,
            datatestId: 'cancel',
          },
        ],
      },
      {
        title: t('sideMenu.informationLabel'),
        isHidden: !isWaasApp,
        actions: [
          {
            label: t('sideMenu.actions.downloadApp'),
            Icon: DownloadOutlined,
            handleClick: handleWaasDownloadApp,
          },
        ],
      },
      {
        title: t('sideMenu.settingsLabel'),
        actions: [
          {
            label: t('sideMenu.actions.language'),
            Icon: Language,
            datatestId: 'language',
            secondaryAction: {
              ActionComponent: () => (
                <ToggleLocale currentLocale={currentLocale} onChangeLocale={handleLanguageChange} />
              ),
            },
          },
        ],
      },
      {
        isHidden: isWaasApp,
        actions: [
          {
            label: t('sideMenu.actions.faq'),
            href: faqLink,
            datatestId: 'FAQ',
          },
          {
            label: t('sideMenu.actions.impressum'),
            href: imprintLink,
            datatestId: 'imprint',
          },
        ],
      },
    ];
  }, [currentLocale, t, handleLanguageChange, handleProcessCancel, handleWaasDownloadApp, isWaasApp]);
};
