import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import { List, ListItem, Stack, Typography } from '@mui/material';

import { waasQueries } from '@/queries/waas';

import { QueryParamsWithProcessId } from '@/types/types';

export const WaasCancelModalContent = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { processId } = router.query as QueryParamsWithProcessId;
  const { data: waasIdentData } = waasQueries.useIdent({ processId });

  return (
    <Stack spacing={2} data-testid="cancel-modal-content">
      <List
        subheader={<Typography sx={{ pb: 2 }}>{t('cancelProcess.waasContent.header')}</Typography>}
        sx={{
          listStyleType: 'decimal',
          listStylePosition: 'inside',
          '& .MuiListItem-root': {
            display: 'list-item',
          },
        }}>
        <ListItem dense>{t('cancelProcess.waasContent.list.item1')}</ListItem>
        <ListItem dense>{t('cancelProcess.waasContent.list.item2')}</ListItem>
        <ListItem dense>{t('cancelProcess.waasContent.list.item3')}</ListItem>
      </List>
      <Typography>
        <b>{t('cancelProcess.waasContent.footer')}</b>
      </Typography>
      {waasIdentData?.egkVerification && <Typography>{t('cancelProcess.waasContent.egkVerificationText')}</Typography>}
      <Typography>{t('cancelProcess.waasContent.reallyCancel')}</Typography>
    </Stack>
  );
};
