import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { commonApi } from '@/api/common';
import { identApi } from '@/api/ident';

import { MUTATIONS } from '@/queries/mutations';
import { QUERIES } from '@/queries/queries';

import {
  PosOCRDataPayload,
  PosScanEventPayload,
  UpdatedUserDetails,
  UpdatedUserPersonalDetailsFormData,
  UserDetails,
} from '@/types/interfaces';
import {
  ErrorResponse,
  PosAllowedDocumentsResponse,
  PosConsentLinksResponse,
  PosIdentLocalLoginResponse,
  PosOCRDataResponse,
  PosRedirectUrlResponse,
  PosSignServiceProcessIdResponse,
} from '@/types/responses';
import { QueryParamsWithProcessAndSubProcessId } from '@/types/types';

// Group queries and apis based on flows? Local-Ident + Local-Verify + Local-Sign?
export const identQueries = {
  useConsentLinks: (params: { processId: string; identId: string }) => {
    const query = useQuery<
      PosConsentLinksResponse,
      ErrorResponse,
      PosConsentLinksResponse,
      [string, { processId: string; identId: string }]
    >({
      queryKey: [QUERIES.CONSENT_LINKS_RETRIEVE, params],
      queryFn: () => identApi.consentLinks(params),
      enabled: !!params.identId && !!params.processId,
    });
    return query;
  },
  useLocalLogin: () => {
    const query = useMutation<PosIdentLocalLoginResponse, ErrorResponse, { processId: string; identId: string }>({
      mutationKey: [MUTATIONS.LOCAL_LOGIN_RETRIEVE],
      mutationFn: ({ processId, identId }) => identApi.localLogin({ processId, identId }),
    });
    return query;
  },
  useUpdateUserPersonalDetails: () => {
    const mutation = useMutation<
      unknown,
      ErrorResponse,
      { params: { processId: string; identId: string }; data: UpdatedUserPersonalDetailsFormData }
    >({
      mutationKey: [MUTATIONS.USER_PERSONAL_DETAILS_UPDATE],
      mutationFn: ({ params, data }) =>
        identApi.updateUserPersonalDetails({ processId: params.processId, identId: params.identId }, data),
    });
    return mutation;
  },
  useRetrieveUserDetails: (params: { processId?: string }) => {
    const query = useQuery<UserDetails, ErrorResponse, UserDetails, [string, { processId?: string }]>({
      queryKey: [QUERIES.USER_DETAILS_RETRIEVE, params],
      queryFn: () => identApi.retrieveUserDetails({ processId: String(params.processId) }),
      enabled: !!params.processId,
    });
    return query;
  },
  useUpdateUserDetails: (params: { processId: string }) => {
    const mutation = useMutation<UserDetails, ErrorResponse, { processId: string; data: UpdatedUserDetails }>({
      mutationKey: [MUTATIONS.USER_DETAILS_UPDATE, params],
      mutationFn: ({ data }) => identApi.updateUserDetails(params, data),
    });
    return mutation;
  },
  useAllowedDocuments: (params: { processId: string }) => {
    const query = useQuery<
      PosAllowedDocumentsResponse,
      ErrorResponse,
      PosAllowedDocumentsResponse,
      [string, { processId: string }]
    >({
      queryKey: [QUERIES.ALLOWED_DOCUMENTS_RETRIEVE, params],
      queryFn: () => identApi.allowedDocuments(params),
    });
    return query;
  },
  useUpdateOCRData: () => {
    const queryClient = useQueryClient();
    const mutation = useMutation<
      PosOCRDataResponse,
      ErrorResponse,
      { params: { processId: string; identId: string }; data: PosOCRDataPayload }
    >({
      mutationKey: [MUTATIONS.OCR_DATA_UPDATE],
      mutationFn: ({ params, data }) =>
        identApi.updateOCRData({ processId: params.processId, identId: params.identId }, data),
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: [QUERIES.OCR_DATA_RETRIEVE] });
      },
    });
    return mutation;
  },
  useRetrieveOCRData: (params: { processId: string; identId: string }) => {
    const query = useQuery<
      PosOCRDataResponse,
      ErrorResponse,
      PosOCRDataResponse,
      [string, { processId: string; identId: string }]
    >({
      queryKey: [QUERIES.OCR_DATA_RETRIEVE, params],
      queryFn: () => identApi.retrieveOCRData(params),
      enabled: !!(params.processId && params.identId),
    });
    return query;
  },
  useRetrieveSignServiceProcessId: () => {
    const query = useMutation<PosSignServiceProcessIdResponse, ErrorResponse, { processId: string; signId: string }>({
      mutationKey: [MUTATIONS.SIGNIN_SERVICE_PROCESS_ID_GET],
      mutationFn: params => identApi.retrieveSignServiceProcessId(params),
    });
    return query;
  },
  useRetrieveRedirectUrl: () => {
    const query = useMutation<PosRedirectUrlResponse, ErrorResponse, QueryParamsWithProcessAndSubProcessId>({
      mutationKey: [MUTATIONS.REDIRECT_URL_RETRIEVE],
      mutationFn: params => identApi.retrieveRedirectUrl(params),
    });
    return query;
  },
  useScanEvent: (params: { processId: string }) => {
    const query = useMutation<object, ErrorResponse, PosScanEventPayload>({
      mutationKey: [MUTATIONS.SCAN_EVENTS],
      mutationFn: payload => commonApi.scanEvent(params, payload),
    });
    return query;
  },
  useCancelProcess: () => {
    const mutation = useMutation<unknown, ErrorResponse, QueryParamsWithProcessAndSubProcessId>({
      mutationKey: [MUTATIONS.CANCEL_PROCESS],
      mutationFn: params => commonApi.cancelProcess(params),
    });
    return mutation;
  },
};
