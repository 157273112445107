import { FC, useCallback, useEffect, useRef, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { Verimi } from '@/assets/svg/Verimi';

import { Icon } from '@/components/common/Icon/Icon';
import { PartnerLogo } from '@/components/common/PartnerLogo/PartnerLogo';
import { SidebarMenuSection } from '@/components/common/Sidebar/SidebarMenuSection';
import { Drawer, DrawerFooter, DrawerHeader } from '@/components/common/Sidebar/styles';

import { useMenuStructure } from '@/hooks/useMenuStructure';

import { waasQueries } from '@/queries/waas';

import { DrawerMenuGroup } from './types';

export const Sidebar: FC = () => {
  const [open, setOpen] = useState(false);
  const sidebarRef = useRef<HTMLDivElement>(null);

  const useIsWaas = waasQueries.useIsWaas();
  const isWaasApp = useIsWaas.data?.isWaas;

  const toggleDrawer = useCallback(() => {
    setOpen(prev => !prev);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setOpen(false);
  }, []);

  const sidebarMenuStructure = useMenuStructure({ handleDrawerClose });

  useEffect(() => {
    const closeEventHandler = ({ target }: MouseEvent) => {
      if (sidebarRef.current && !sidebarRef.current.contains(target as Node) && open) {
        handleDrawerClose();
      }
    };

    document.addEventListener('click', closeEventHandler, true);

    return () => {
      document.removeEventListener('click', closeEventHandler, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Box sx={{ display: 'flex' }} ref={sidebarRef}>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader open={open}>
          {open && <Typography variant="h6">{isWaasApp ? 'Local-Ident' : 'Verimi Business'}</Typography>}
          <IconButton color="inherit" onClick={toggleDrawer} data-testid="open-drawer-btn">
            {open ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        </DrawerHeader>
        {open && (
          <Box data-testid="sidebar-menu">
            {sidebarMenuStructure.map(
              ({ title, actions, isHidden }: DrawerMenuGroup, index: number) =>
                !isHidden && (
                  <SidebarMenuSection
                    key={`${title}-${index}`}
                    title={title}
                    actions={actions}
                    showDivider={index < sidebarMenuStructure.length - 1}
                  />
                ),
            )}
          </Box>
        )}
        <DrawerFooter>
          <PartnerLogo />
          {!isWaasApp && <Icon src={<Verimi />} />}
        </DrawerFooter>
      </Drawer>
    </Box>
  );
};
