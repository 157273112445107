import { ListItemButton, ListItemIcon, ListItemSecondaryAction } from '@mui/material';

import { SidebarActionText } from '@/components/common/Sidebar/styles';
import { DrawerMenuAction } from '@/components/common/Sidebar/types';

export const SidebarMenuAction = ({
  handleClick,
  label,
  Icon: ActionMenuIcon,
  href,
  secondaryAction,
  ActionComponent,
  datatestId,
}: DrawerMenuAction) => {
  if (ActionComponent) {
    return <ActionComponent data-testid={datatestId} />;
  }

  return (
    <ListItemButton
      data-testid={datatestId}
      onClick={handleClick}
      {...(href && { href, component: 'a', target: '_blank', 'data-testid': datatestId })}>
      {ActionMenuIcon && (
        <ListItemIcon>
          <ActionMenuIcon />
        </ListItemIcon>
      )}
      <SidebarActionText primary={label} />
      {secondaryAction && (
        <ListItemSecondaryAction>
          <SidebarMenuAction {...secondaryAction} />
        </ListItemSecondaryAction>
      )}
    </ListItemButton>
  );
};
