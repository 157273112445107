import { ListItemText } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { CSSObject, styled, Theme } from '@mui/material/styles';

const DRAWER_WIDTH = 336;

const drawerCommonCss = (theme: Theme): CSSObject => ({
  background: theme.palette.background.paper,
  borderRight: 'none',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  padding: 12,
  color: '#3F4946',
  boxShadow: '1px 0 2px -2px #888',
});
const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  ...drawerCommonCss(theme),
});

const closedMixin = (theme: Theme): CSSObject => ({
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
  ...drawerCommonCss(theme),
});

export const DrawerHeader = styled('div')<{ open: boolean }>(({ theme, open }) => ({
  display: 'flex',
  justifyContent: open ? 'space-between' : 'center',
  alignItems: 'center',
  padding: theme.spacing(1, 2),
}));

export const DrawerFooter = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(1, 2),
  marginTop: 'auto',
}));

export const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  padding: theme.spacing(2),
  '.MuiListItemIcon-root': {
    minWidth: theme.spacing(5),
  },
  '& .MuiDrawer-paper': {
    background: theme.palette.background.paper,
  },
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const SidebarActionText = styled(ListItemText)(() => ({
  ['span']: {
    fontWeight: 500,
  },
}));
