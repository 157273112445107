import { Query, useMutation, useQuery } from '@tanstack/react-query';

import { commonApi } from '@/api/common';

import { MUTATIONS } from '@/queries/mutations';
import { QUERIES } from '@/queries/queries';

import { Country } from '@/types/interfaces';
import {
  ErrorResponse,
  InternalPartnerLogoResponse,
  MasterDataServiceServiceProviderDataResponse,
  PosRegisterOptionResponse,
  PosServiceProviderIdResponse,
  PosStatusesResponse,
} from '@/types/responses';
import { RequestWithProcessId } from '@/types/types';

export const commonQueries = {
  useStatuses: (
    params: { processId?: string },
    interval?:
      | number
      | false
      | ((
          data: PosStatusesResponse | undefined,
          query: Query<PosStatusesResponse, ErrorResponse, PosStatusesResponse, [string, { processId?: string }]>,
        ) => number | false),
  ) => {
    const query = useQuery<PosStatusesResponse, ErrorResponse, PosStatusesResponse, [string, { processId?: string }]>({
      queryKey: [QUERIES.STATUSES_RETRIEVE, params],
      queryFn: () => commonApi.statuses({ processId: String(params.processId) }),
      refetchInterval: interval ?? false,
      enabled: !!params.processId,
      staleTime: 0,
      cacheTime: 0,
    });
    return query;
  },
  useStatusesMutation: () => {
    const query = useMutation<PosStatusesResponse, ErrorResponse, { processId: string }>({
      mutationKey: [MUTATIONS.STATUSES_GET],
      mutationFn: params => commonApi.statuses(params),
    });
    return query;
  },
  useCountries: () => {
    const query = useQuery<Country[], ErrorResponse, Country[], [string]>({
      queryKey: [QUERIES.COUNTRIES_LIST],
      queryFn: () => commonApi.countries(),
    });
    return query;
  },
  useRegisterOption: (params: RequestWithProcessId) => {
    const query = useQuery<PosRegisterOptionResponse, ErrorResponse>({
      queryKey: [QUERIES.REGISTER_OPTION_RETRIEVE, params.processId],
      queryFn: () => commonApi.registerOption(params),
    });
    return query;
  },
  useServiceProviderId: (params: { processId: string }) => {
    const query = useQuery<PosServiceProviderIdResponse, ErrorResponse>({
      queryKey: [QUERIES.SERVICE_PROVIDER_ID_RETRIEVE, params.processId],
      queryFn: () => commonApi.serviceProviderId(params),
      staleTime: 20 * 60 * 1000,
      cacheTime: 25 * 60 * 1000,
    });
    return query;
  },
  useServiceProviderIdIdMutation: () => {
    const mutation = useMutation<PosServiceProviderIdResponse, ErrorResponse, { processId: string }>({
      mutationKey: [MUTATIONS.SERVICE_PROVIDER_ID_GET],
      mutationFn: params => commonApi.serviceProviderId(params),
    });
    return mutation;
  },
  useServiceProviderData: ({ serviceProviderId }: { serviceProviderId: string }) => {
    const query = useQuery<MasterDataServiceServiceProviderDataResponse, ErrorResponse>({
      queryKey: [QUERIES.SERVICE_PROVIDER_DATA_RETRIEVE, serviceProviderId],
      queryFn: () => commonApi.serviceProviderData({ serviceProviderId }),
      enabled: !!serviceProviderId,
      staleTime: 20 * 60 * 1000,
      cacheTime: 25 * 60 * 1000,
    });
    return query;
  },
  usePartnerLogo: (params: { processId: string }) => {
    const query = useQuery<
      InternalPartnerLogoResponse,
      ErrorResponse,
      InternalPartnerLogoResponse,
      [string, { processId: string }]
    >({
      queryKey: [QUERIES.PARTNER_LOGO_RETRIEVE, params],
      queryFn: () => commonApi.partnerLogo(params),
      enabled: !!params.processId,
    });
    return query;
  },
};
