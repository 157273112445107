import { FC, memo, useEffect, useState } from 'react';

import { useTranslation } from 'next-i18next';
import Image, { ImageLoader, ImageLoaderProps } from 'next/image';
import { useRouter } from 'next/router';

import { Box } from '@mui/material';

import { PARTNER_LOGO_SIZE } from '@/components/common/PartnerLogo/PartnerLogo.constants';

import { DEFAULT_LOADING_SPINNER_PATH } from '@/constants/common';

import { commonQueries } from '@/queries/common';

import { isDev } from '@/utils/environment';

const partnerLogoLoader: ImageLoader = ({ src }: ImageLoaderProps): string =>
  isDev() ? DEFAULT_LOADING_SPINNER_PATH : src;

export const PartnerLogo: FC = memo(function PartnerLogoComponent() {
  const router = useRouter();
  const { t } = useTranslation();

  const processId = router.query.processId as string;

  const [src, setSrc] = useState<string | null>(null);

  const usePartnerLogo = commonQueries.usePartnerLogo({ processId });

  useEffect(() => {
    setSrc(usePartnerLogo.data?.url || '');
  }, [processId, usePartnerLogo.data?.url]);

  if (!src) {
    return null;
  }

  return (
    <Box sx={{ position: 'relative', height: PARTNER_LOGO_SIZE, width: PARTNER_LOGO_SIZE }}>
      <Image
        fill
        src={src}
        alt={t('partnerLogoAlt')}
        loader={partnerLogoLoader}
        onError={() => {
          setSrc(null);
        }}
      />
    </Box>
  );
});
