/* eslint-disable import/no-extraneous-dependencies */
import { FC, memo, PropsWithChildren } from 'react';

import { Grid } from '@mui/material';
import { styled } from '@mui/system';

import { Sidebar } from '@/components/common/Sidebar/Sidebar';

const BaseContainer = styled(Grid)({
  width: '100%',
  minHeight: '100vh',
});

const SidebarContainer = styled(Grid)({
  width: '80px',
  zIndex: 100,
});

const RightContainer = styled(Grid)({
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
});

export const BaseLayout: FC = memo(function BaseLayoutComponent({ children }: PropsWithChildren) {
  return (
    <BaseContainer container>
      <SidebarContainer item>
        <Sidebar />
      </SidebarContainer>
      <RightContainer item xs>
        {children}
      </RightContainer>
    </BaseContainer>
  );
});
