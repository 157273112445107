import { useMutation, useQuery } from '@tanstack/react-query';

import { waasApi } from '@/api/waas';

import { MUTATIONS } from '@/queries/mutations';
import { QUERIES } from '@/queries/queries';

import { UserTypePayload, WaasParPayload, WaasRegisterUserDetails } from '@/types/interfaces';
import {
  ErrorResponse,
  InternalIsWaasResponse,
  UserTypeResponse,
  WaasCodeChallengeResponse,
  WaasIdentResponse,
  WaasParResponse,
} from '@/types/responses';
import { RequestWithProcessId } from '@/types/types';

export const waasQueries = {
  usePar: () => {
    const mutation = useMutation<WaasParResponse, ErrorResponse, WaasParPayload>({
      mutationKey: [MUTATIONS.WAAS_PAR],
      mutationFn: params => waasApi.par(params),
    });
    return mutation;
  },
  useRegisterUserDetails: () => {
    const mutation = useMutation<void, ErrorResponse, WaasRegisterUserDetails>({
      mutationKey: [MUTATIONS.WAAS_REGISTER_USER_DETAILS],
      mutationFn: data => waasApi.registerUserDetails(data),
    });
    return mutation;
  },
  useUserType: () => {
    const mutation = useMutation<UserTypeResponse, ErrorResponse, UserTypePayload>({
      mutationKey: [MUTATIONS.USER_TYPE_SELECT],
      mutationFn: payload => waasApi.userType(payload),
    });
    return mutation;
  },
  useCodeChallenge: () => {
    const mutation = useMutation<WaasCodeChallengeResponse, ErrorResponse, RequestWithProcessId>({
      mutationKey: [MUTATIONS.WAAS_CODE_CHALLENGE],
      mutationFn: waasApi.codeChallenge,
    });
    return mutation;
  },
  useIsWaas: () => {
    const query = useQuery<InternalIsWaasResponse>({
      queryKey: [QUERIES.IS_WAAS],
      queryFn: () => waasApi.isWaas(),
      staleTime: Infinity,
    });
    return query;
  },
  useIdent: (params: { processId?: string }) => {
    const query = useQuery<WaasIdentResponse, ErrorResponse>({
      queryKey: [QUERIES.WAAS_IDENT, params.processId],
      queryFn: () => waasApi.ident(String(params.processId)),
      enabled: !!params.processId,
      staleTime: 20 * 60 * 1000,
      cacheTime: 25 * 60 * 1000,
    });
    return query;
  },
};
