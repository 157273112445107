import { FC, memo } from 'react';

import { Stack } from '@mui/material';

interface IconProps {
  src: JSX.Element;
}

export const Icon: FC<IconProps> = memo(function Icon({ src }) {
  return <Stack direction="column">{src}</Stack>;
});
